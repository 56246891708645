exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-500-tsx": () => import("./../../../src/pages/500.tsx" /* webpackChunkName: "component---src-pages-500-tsx" */),
  "component---src-pages-blog-[slug]-tsx": () => import("./../../../src/pages/blog/[slug].tsx" /* webpackChunkName: "component---src-pages-blog-[slug]-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-company-careers-index-tsx": () => import("./../../../src/pages/company/careers/index.tsx" /* webpackChunkName: "component---src-pages-company-careers-index-tsx" */),
  "component---src-pages-company-index-tsx": () => import("./../../../src/pages/company/index.tsx" /* webpackChunkName: "component---src-pages-company-index-tsx" */),
  "component---src-pages-company-testimonials-index-tsx": () => import("./../../../src/pages/company/testimonials/index.tsx" /* webpackChunkName: "component---src-pages-company-testimonials-index-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-aml-index-tsx": () => import("./../../../src/pages/legal/aml/index.tsx" /* webpackChunkName: "component---src-pages-legal-aml-index-tsx" */),
  "component---src-pages-legal-disclaimer-index-tsx": () => import("./../../../src/pages/legal/disclaimer/index.tsx" /* webpackChunkName: "component---src-pages-legal-disclaimer-index-tsx" */),
  "component---src-pages-legal-pad-index-tsx": () => import("./../../../src/pages/legal/pad/index.tsx" /* webpackChunkName: "component---src-pages-legal-pad-index-tsx" */),
  "component---src-pages-legal-privacy-index-tsx": () => import("./../../../src/pages/legal/privacy/index.tsx" /* webpackChunkName: "component---src-pages-legal-privacy-index-tsx" */),
  "component---src-pages-legal-terms-index-tsx": () => import("./../../../src/pages/legal/terms/index.tsx" /* webpackChunkName: "component---src-pages-legal-terms-index-tsx" */),
  "component---src-pages-merchants-list-index-tsx": () => import("./../../../src/pages/merchants/list/index.tsx" /* webpackChunkName: "component---src-pages-merchants-list-index-tsx" */),
  "component---src-pages-merchants-map-index-tsx": () => import("./../../../src/pages/merchants/map/index.tsx" /* webpackChunkName: "component---src-pages-merchants-map-index-tsx" */),
  "component---src-pages-partner-index-tsx": () => import("./../../../src/pages/partner/index.tsx" /* webpackChunkName: "component---src-pages-partner-index-tsx" */),
  "component---src-pages-partner-partner-type-tsx": () => import("./../../../src/pages/partner/[partnerType].tsx" /* webpackChunkName: "component---src-pages-partner-partner-type-tsx" */),
  "component---src-pages-products-api-index-tsx": () => import("./../../../src/pages/products/api/index.tsx" /* webpackChunkName: "component---src-pages-products-api-index-tsx" */),
  "component---src-pages-products-crypto-payouts-index-tsx": () => import("./../../../src/pages/products/crypto-payouts/index.tsx" /* webpackChunkName: "component---src-pages-products-crypto-payouts-index-tsx" */),
  "component---src-pages-products-customrequests-index-tsx": () => import("./../../../src/pages/products/customrequests/index.tsx" /* webpackChunkName: "component---src-pages-products-customrequests-index-tsx" */),
  "component---src-pages-products-invoicing-index-tsx": () => import("./../../../src/pages/products/invoicing/index.tsx" /* webpackChunkName: "component---src-pages-products-invoicing-index-tsx" */),
  "component---src-pages-products-terminal-index-tsx": () => import("./../../../src/pages/products/terminal/index.tsx" /* webpackChunkName: "component---src-pages-products-terminal-index-tsx" */),
  "component---src-pages-usecases-charities-and-non-profits-index-tsx": () => import("./../../../src/pages/usecases/charities-and-non-profits/index.tsx" /* webpackChunkName: "component---src-pages-usecases-charities-and-non-profits-index-tsx" */),
  "component---src-pages-usecases-ecommerce-payments-index-tsx": () => import("./../../../src/pages/usecases/ecommerce-payments/index.tsx" /* webpackChunkName: "component---src-pages-usecases-ecommerce-payments-index-tsx" */),
  "component---src-pages-usecases-event-ticketing-index-tsx": () => import("./../../../src/pages/usecases/event-ticketing/index.tsx" /* webpackChunkName: "component---src-pages-usecases-event-ticketing-index-tsx" */),
  "component---src-pages-usecases-investments-index-tsx": () => import("./../../../src/pages/usecases/investments/index.tsx" /* webpackChunkName: "component---src-pages-usecases-investments-index-tsx" */),
  "component---src-pages-usecases-payment-service-providers-index-tsx": () => import("./../../../src/pages/usecases/payment-service-providers/index.tsx" /* webpackChunkName: "component---src-pages-usecases-payment-service-providers-index-tsx" */),
  "component---src-pages-usecases-professional-services-index-tsx": () => import("./../../../src/pages/usecases/professional-services/index.tsx" /* webpackChunkName: "component---src-pages-usecases-professional-services-index-tsx" */)
}

