import React, { createContext, useReducer } from "react"
let SET_THEME

export const ThemeContext = createContext(null)

export const ThemeReducer = (state, action) => {
  switch (action.type) {
    case SET_THEME:
      return {
        ...state,
        darkMode: action.payload
      }
    default:
      return state
  }
}

export const DarkModeState = props => {
  const initialState = {
    darkMode: "false"
  }
  const [state, dispatch] = useReducer(ThemeReducer, initialState)

  const setDarkMode = async bool => {
    dispatch({
      type: SET_THEME,
      payload: bool
    })
  }

  return (
    <ThemeContext.Provider
      value={{
        darkMode: state.darkMode,
        setDarkMode
      }}
    >
      {props.children}
    </ThemeContext.Provider>
  )
}
